import axios from 'axios'
import { removeFeeItemsFromReservation } from '../utils'

const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const getReservationParts = (reservationToken) =>
  axios({
    headers,
    method: 'get',
    url: `/api/reservation/parts/${reservationToken}`,
  })

export const getReservation = (reservationToken) =>
  axios({
    headers,
    method: 'get',
    url: `/api/reservation/${reservationToken}`,
  })

export const getRefundInfo = ({ reservationToken, sailRefIds }) =>
  axios({
    headers,
    method: 'post',
    url: `/api/reservation/${reservationToken}/refund/sails`,
    data: {
      sailRefIds,
    },
  })

export const createReservation = (reservation) =>
  axios({
    headers,
    method: 'post',
    url: '/api/reservation/create',
    data: reservation,
  })

export const updateReservation = ({ reservationId, token, ...items }) => {
  if (reservationId > 0 && token) {
    return axios({
      headers,
      method: 'put',
      url: `/api/reservationByToken/${token}`,
      data: items,
    })
  }
  return axios({
    headers,
    method: 'put',
    url: `/api/reservation/${reservationId}`,
    data: items,
  })
}

export const extendReservation = (tempReservationId) =>
  axios({
    headers,
    method: 'put',
    url: `/api/reservation/${tempReservationId}/extend`,
  })

export const refundReservation = ({ reservationToken, sailRefIds }) =>
  axios({
    headers,
    method: 'post',
    url: `/api/reservation/${reservationToken}/refund`,
    data: { sailRefIds },
  })

export const removeTripPackage = ({ reservationToken, seqN }) =>
  axios({
    method: 'DELETE',
    url: `/api/reservation/${reservationToken}/${seqN}`,
  })

export const newReservationCreator = (reservation) =>
  createReservation(reservation)
    .then((response) => ({ response: removeFeeItemsFromReservation(response) }))
    .catch((error) => ({ error }))

export const reservationUpdater = (update) =>
  updateReservation(update)
    .then((response) => ({ response: removeFeeItemsFromReservation(response) }))
    .catch((error) => ({ error }))

export const fetchPaymentMethods = (reservationToken) =>
  axios.get(`/api/reservation/${reservationToken}/paymentMethods`)

export const fetchPaymentPayload = (reservationToken, paymentMethod) =>
  axios.get(`api/reservation/${reservationToken}/paymentMethods/${paymentMethod}/paymentPayload`)

export function reservationModificationStartService(reservationToken) {
  return axios({
    headers,
    method: 'post',
    url: `/api/reservation/${reservationToken}/startEdit`,
  })
}

export function reservationModificationCancelService(reservationToken) {
  return axios({
    headers,
    method: 'post',
    url: `/api/reservation/${reservationToken}/cancelEdit`,
  })
}

export function reservationModificationFinishService(reservationToken) {
  return axios({
    headers,
    method: 'post',
    url: `/api/reservation/${reservationToken}/finishEdit`,
  })
}

export function reservationModificationModifyService(reservationToken, modifications) {
  return axios({
    headers,
    method: 'put',
    url: `/api/reservation/${reservationToken}/editData`,
    data: modifications,
  })
}

export function reservationEmptyModificationModifyService(reservationId) {
  return axios({
    headers,
    method: 'put',
    url: `/api/reservation/${reservationId}/editData`,
    data: {},
  })
}

export function applyPromotion({ reservationId, code }) {
  return axios({
    headers,
    method: 'POST',
    url: `/api/reservation/${reservationId}/promotion/${code}`,
    data: {},
  })
}

export function removePromotion({ reservationId }) {
  return axios({
    headers,
    method: 'DELETE',
    url: `/api/reservation/${reservationId}/promotion/`,
    data: {},
  })
}

export function reservationModificationPassengerEditService(reservationId, modifications, seqN) {
  return axios({
    headers,
    method: 'put',
    url: `/api/reservation/${reservationId}/guest/${seqN}`,
    data: modifications,
  })
}

export const getDateValidationResult = (reservationId, birthday, seqN) =>
  axios({
    headers,
    method: 'put',
    url: `api/reservation/${reservationId}/guest/${seqN}/ageCategory/verify`,
    data: {
      birthday,
    },
  })

export function reservationStatus(reservationId) {
  return axios({
    headers,
    method: 'GET',
    url: `/api/reservation/${reservationId}/status`,
  })
}

export const fetchPriceCategoryForPersonalId = (route, personalId, departureDate) =>
  axios.get(`/api/reservation/route/${route}/resident/${personalId}/priceCategory?departureDate=${departureDate}`)

export const checkPersonalIdOnSailAndReservation = ({ reservationId, personalId, sailRefId }) =>
  axios.get(`/api/reservation/${reservationId}/sail/${sailRefId}/resident/${personalId}/priceCategory`)

export const createReservationForGroups = (data) =>
  axios({
    headers,
    method: 'post',
    url: 'api/reservationForGroups/create',
    data,
  })

export const modifyReservationForGroups = (data, reservationId) =>
  axios({
    headers,
    method: 'post',
    url: `api/reservationForGroups/${reservationId}/modify`,
    data,
  })
